import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from '@/api/index'
import VantGreen from 'vant-green';
import './assets/css/pres.less'
import ElementUI from 'element-ui';
import module_axios from './module/api/index'
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/index.less';
import 'vant-green/lib/index.css';
import "./assets/css/public.css"
import './assets/css/chat.css'
import { TUIComponents, TUIChatKit } from "./TUIKit";
import { TUITranslateService } from "@tencentcloud/chat-uikit-engine";
// import VueCompositionAPI from "@vue/composition-api";
// Vue.use(VueCompositionAPI);


// import VueCompositionAPI from "@vue/composition-api";
// Vue.use(VueCompositionAPI);



// 3. 



// import { TUICallKit } from '@tencentcloud/call-uikit-vue';
// import VueCompositionAPI from "@vue/composition-api";
// Vue.use(VueCompositionAPI);


// import { TUICallKit } from "@tencentcloud/call-uikit-vue2.6";



TUIChatKit.init();
TUITranslateService.useI18n();
Vue.prototype.$api_token = axios
Vue.prototype.$api = module_axios
Vue.use(VantGreen);

Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
