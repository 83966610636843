var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"message-bubble"},[_c('div',{staticClass:"message-bubble-main-content",class:[_vm.message.flow === 'in' ? '' : 'reverse']},[_c('img',{staticClass:"message-avatar",attrs:{"src":_vm.message.avatar || 'https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png',"onerror":"this.onerror=null;this.src='https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png'"}}),_c('main',{staticClass:"message-body"},[(_vm.message.flow === 'in' && _vm.message.conversationType === 'GROUP')?_c('div',{staticClass:"message-body-nickName"},[_vm._v(" "+_vm._s(_vm.ChatPrseInfo.DoctorName)+" ")]):_vm._e(),_c('div',{class:[
          'blink',
          'message-body-content',
          _vm.message.flow === 'out' ? 'content-out' : 'content-in',
          _vm.message.hasRiskContent && 'content-has-risk',
          _vm.isNoPadding ? 'content-noPadding' : '',
          _vm.isNoPadding && _vm.isBlink ? 'blink-shadow' : '',
          !_vm.isNoPadding && _vm.isBlink ? 'blink-content' : '',
        ]},[_c('div',{staticClass:"content-main"},[(
              (_vm.message.type === _vm.TYPES.MSG_IMAGE || _vm.message.type === _vm.TYPES.MSG_VIDEO) &&
              _vm.message.hasRiskContent
            )?_c('img',{class:['message-risk-replace', !_vm.isPC && 'message-risk-replace-h5'],attrs:{"src":_vm.riskImageReplaceUrl}}):_vm._t("default")],2),(_vm.message.hasRiskContent)?_c('div',{staticClass:"content-has-risk-tips"},[_vm._v(" "+_vm._s(_vm.riskContentText)+" ")]):_vm._e()])]),(_vm.message.status === 'fail' || _vm.message.hasRiskContent)?_c('div',{staticClass:"message-label fail",on:{"click":function($event){return _vm.resendMessage()}}},[_vm._v(" ! ")]):_vm._e(),(_vm.message.status === 'unSend' && _vm.needLoadingIconMessageType.includes(_vm.message.type))?_c('Icon',{staticClass:"message-label loadingCircle",attrs:{"file":_vm.loadingIcon,"width":'15px',"height":'15px'}}):_vm._e(),(
        _vm.message.conversationType === 'C2C' &&
        _vm.message.flow === 'out' &&
        _vm.message.status === 'success'
      )?_c('div',{staticClass:"message-label",class:[!_vm.message.isPeerRead && 'unRead']},[(!_vm.message.isPeerRead)?_c('span',[_vm._v("未读")]):_c('span',[_vm._v("已读")])]):_vm._e(),(
        _vm.message.conversationType === 'GROUP' &&
        _vm.message.flow === 'out' &&
        _vm.message.status === 'success'
      )?_c('div',{staticClass:"message-label",class:[!_vm.message.isRead && 'unRead']}):_vm._e()],1),_c('div',{staticClass:"message-bubble-extra-content",class:_vm.message.flow === 'in' || 'reverse'},[_c('MessageQuote',{attrs:{"message":_vm.message},on:{"blinkMessage":_vm.blinkMessage,"scrollTo":_vm.scrollTo}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }