<template>
    <div class="bbb Drugs">
        <van-nav-bar
        title="统筹问诊申请"
        left-arrow
        @click-left="onClickLeft"
        />
      <div id="Interrogation" class="Drugs time_slot">
        <div class="jiuzhenren bpd" style="position: relative;">
            <div class="title">
                <span class="title_icon">| </span>
                <span class="DrugsTitle">
                    就诊人信息<span :class="patientId == '' ? 'required' : 'required_success'">（必填）</span>
                </span>
            </div>
            <div class="jiuzhenren_xuanzhe margin_top"  style="display: flex;justify-content: space-between; align-items: center;padding-left: 10px; margin-top: 0.5rem !important;">
                <el-select v-model="patientId" @change="PatientChange"  placeholder="请选择">
                    <el-option
                    v-for="item in PatientList"
                    :key="item.patient_id"
                    :label="item.name + `${item.code == '200' ? '（已授权）' : '（未授权）'}`"
                    :value="item.patient_id">
                    <span style="float: left">{{ item.name }}</span>
                    <p v-if="item.code == '200'" style="float: right;  font-size: 12px; padding-left: 20px;display: flex;align-items: center;">
                         <i style="color: hsl(138, 65%, 36%); font-size: 20px" class="el-icon-check"></i>
                         <a href="javascript:void(0);"  style=" text-decoration: underline;color: #409EFF;">医保授权</a>
                        </p>
                    <p v-else="item.code !== '200'" style="float: right;  font-size: 12px; padding-left: 20px;display: flex;align-items: center;"> 
                        <i style="color: hwb(355 13% 41%); font-size: 20px;padding-top: 1px;" class="el-icon-close"></i>
                        <a href="javascript:void(0);" @click="patientChange" style=" text-decoration: underline;color: hsl(138, 65%, 36%);">请到医保授权</a>
                    </p>
                    </el-option>
                </el-select>

                <!-- 请添加就诊人 -->
                <van-button icon="plus" :class="PatientList.length == 0 ? 'color_Danger' : ''" @click="post_Patient" size="mini">{{ PatientList.length == 0 ? '请添加就诊人' : '添加新的就诊人' }}</van-button>
            </div>
            <div class="yyxz_btn" style="padding:0 16px">
            </div>
        </div>

        <div class="yyxz margin_top bpd" style="height: auto;">
            <div class="title Drugs_title">
                <div class="DrugsTitle">
                    <span class="title_icon">| </span>
                    历史用药<span :class="Drugs.length == 0 ? 'required' : 'required_success'">（必填）</span>
                </div>
                <van-button v-if="Drugs.length !==0"  icon='disease-bank' @click="drugsShow" size="mini">药品详情</van-button>
            </div>
            <div class='yyxz_btn'>
                <van-button  icon='report-query' @click="yaopionxuanze('Drugs')" size="mini">药品录入</van-button>
                <input
                ref="customFileInput"
                type="file"
                id="customFileInput"
                style="display: none;"
                @change="handleFileChange"
                accept="image/*"
                >
            </div>
            <div class="title bpd text" v-for="(item,index) of Drugs" style="padding-bottom: 5px; padding-left: 16px;">
              <div>{{ item.name }} <span style="color:#000"> × {{ item.number }}</span></div>
            </div>
            
        </div>
        <div>
            <barCode v-if="barCode_show"></barCode>
        </div>
        <div class="jjxx  margin_top bpd">
            <div class="title ">
                <span class="title_icon">| </span>
                
                <span class="DrugsTitle">
                    就诊信息
                </span>
            </div>
            <van-cell-group @click.stop text style="padding: 0 3px; position: relative;">
                <!-- <van-cell @click="disease('disease')" title="确诊疾病" is-link :value="diseasefun('diseaseList')"/> -->
                <van-cell value-class="flex-align-end" @click="disease('disease')">
                    <template slot="title">
                        <div class="flex-align">
                            <span >确诊疾病 </span>
                            <span 
                            ><span :class="diseaseList.length == 0 ? 'required' : 'required_success'">（必填）</span></span
                            >
                        </div>
                    </template>
                    <template>
                        <!-- <span >{{ diseasefun('diseaseList') }}</span> -->
                    </template>
                    <template>
                        <van-icon name="arrow" />
                    </template>
                </van-cell>
                <div style=" position: relative; " class="yaowu">
                    <div class="title bpd Current-text" v-for="(item,index) of diseaseList"  style="padding-bottom: 5px;">
                        <div style="padding-left: 4px;">{{ item.title }}</div>
                    </div>
                    <van-cell
                        v-if="diseaseList.length == 0"
                        cell-class="margin-top12 van-bgcolor-background-color"
                        value-class="line-height32 text-align-center"
                        :border="false"
                    >
                        <template>
                            <span class="font-size0 van-color-text-secondary">请选择确诊疾病</span>
                        </template>
                    </van-cell>
                    
                </div>
                <div v-if="CheckInquiry.suggest !== ''" class="CheckInquiry" :class="CheckInquiry.suggest == '通过' ? 'agree': 'refuse'">
                    <span>{{ CheckInquiry.suggest + '：' }}</span>
                    <span>{{ CheckInquiry.title }}</span>
                </div>
                <van-divider :margin="12" />
                <van-cell value-class="flex-align-end" >
                    <template slot="title">
                        <div class="flex-align">
                            <span >当前病情描述 </span>
                            <span 
                            ><span :class="conditions == '' ? 'required' : 'required_success'">（必填）</span></span
                            >
                        </div>
                    </template>
                </van-cell>
                <van-field
                    type="textarea"
                    v-model="conditions"
                    @input="conditions = conditions.replace(regStr,'')"
                    placeholder="发病时间、主要症状、治疗过程、目前状况等.如用药后有不良反应请说明"
                    rows="2"
                    autosize
                    maxlength="500"
                    show-word-limit
                />
                <div class="symptom_auxiliary" v-if="symptomList.length !== 0">
                    我有点：<el-tag
                    v-for="tag in symptomList"
                    :key="tag"
                    @click="symptomCli(tag)"
                    :type="symptomType(tag)"
                    >
                    {{tag}}
                    </el-tag>
                </div>
            </van-cell-group>
            <van-cell value-class="flex-align-end"  >
                    <template slot="title">
                        <div class="flex-align">
                            <span >复诊证明 </span>
                            <span >
                                <span v-if="!ifOtc()" :class="presDownUrl.length == 0 ? 'required' : 'required_success'">（必填）</span>
                                <span v-else class="required_success">（均为OTC药品，拍药盒上传即可）</span>
                            </span>
                        </div>
                    </template>
            </van-cell>
            <van-cell
            class="presDownUrl-cell"
             v-if="Drugs.length !==0"
                cell-class="margin-top12 van-bgcolor-background-color"
                value-class="line-height32 text-align-center"
                :border="false"
            >
                <template  v-for="(item,index) of Drugs">
                    <p v-if="item.PublicDrugs[0].notify.length >5 || item.notify.length >5"class="font-size0 van-color-text-secondary presDownUrltext">
                        {{item.name}}：{{ item.PublicDrugs[0].notify.length>5 ? item.PublicDrugs[0].notify : item.notify  }}
                    </p>
                  
                </template>
            </van-cell>
            <van-uploader   :max-count="4" class="margin_top" v-model="presImgUrl" multiple :after-read="afterRead"  @delete="imgdelete">
            </van-uploader >
            <p  class="text pading10 " style="line-height: 22px;">上传化验单、检查资料、问诊单、患处照片或其他疾病描述相关资料(最多9张)</p>
            <van-cell-group>
                <van-cell title="过往疾病史" @click="disease('past')" is-link :value="diseasefun('past')"/>
            </van-cell-group>
        </div>


        <div class="yyxz margin_top bpd" style="height: auto;">
            <div class="title Drugs_title">
                <div class="DrugsTitle">
                    <span class="title_icon">| </span>
                    其他
                </div>
            </div>
            <div >
                <el-checkbox-group class=" pading10" v-model="PresFild">
                    <el-checkbox label="已育"></el-checkbox>
                    <el-checkbox label="未育"></el-checkbox>
                    <el-checkbox label="预备1年内育儿"></el-checkbox>
                    <el-checkbox label="患有高血压"></el-checkbox>
                    <el-checkbox label="患有糖尿病"></el-checkbox>
                    <el-checkbox label="抽烟"></el-checkbox>
                    <el-checkbox label="喝酒"></el-checkbox>
                    <el-checkbox label="血脂异常"></el-checkbox>
                </el-checkbox-group>
                <van-cell-group>
                <van-cell title="家族疾病史" @click="disease('family')" is-link :value="diseasefun('family')"/>
                <van-cell-group>
                <van-field
                    type="textarea"
                    v-model="allergy"
                    label="过敏情况"
                    placeholder="请输入过敏情况"
                    rows="2"
                    autosize
                    maxlength="50"
                    show-word-limit
                />
                </van-cell-group>

            </van-cell-group>
            </div>
        </div>
        <van-checkbox v-model="re_diagnosis" icon-size="25px" class="consent margin_top padding-l-20">
            <span class="consent text  ">我是</span>
            <span @click="illustratedShow = true" class="text consent_color">复诊</span>
            
        </van-checkbox>
        <van-checkbox v-model="pastHistory" icon-size="25px" class="consent margin_top padding-l-20">
            <span class="consent text  ">有</span>
            <span @click="illustratedShow = true"  class=" pastHistorytext">既往史</span>
        </van-checkbox>
        <van-checkbox v-model="checked" icon-size="25px" class="consent margin_top padding-l-20">
            <span class="consent text  ">我已阅读</span>
            <span @click="Informed" class="text consent_color"> 知情同意书</span>
        </van-checkbox>
        <div class="jjxx  margin_top  Drugs_btn_mar">
            <van-button color="#02d4d8" @click="CheckInquiry_get" block>开始申请问诊</van-button>
            <!-- <van-button color="#02d4d8" @click="post" block>开始申请问诊</van-button> -->
        </div>

        
        <van-popup v-model="illustratedShow"  position="top" closeable :overlay="true">
            <div class="Informed">
                <h4 style="text-align: center;" class="agreementtitle">复诊说明</h4>
                <div class="text">
                    &nbsp;&nbsp;&nbsp;1、根据《互联网诊疗管理办法（试行）》等国家相关政策法规规定，互联网医院在线问诊仅提供部分适宜的慢病复诊服务。
                </div>
                <div class="text">
                    &nbsp;&nbsp;&nbsp;2、为保证您个人的生命健康安全，建议您在提交前再次仔细核对所填写病情及历史诊疗信息的真实性、客观性，以免因信息填写错误贻误病情。
                </div>
                <div class="text">
                    &nbsp;&nbsp;&nbsp;3、问诊药须凭问诊在药师指导下购买和使用，国药医生仅提供信息技术服务。
                </div>
                <div class="text">
                    &nbsp;&nbsp;&nbsp;4、您成功支付后，如医生长时间未回复或医生审核信息后选择拒诊，系统会在7个工作日内自动退款到您的账户中。
                </div>
                <div class="text">
                    &nbsp;&nbsp;&nbsp;5、使用医保支付患者，如需退费目前仅支持线下退费。
                </div>
                <div class="text">
                    &nbsp;&nbsp;&nbsp;6、医生在线诊疗回复仅为医学建议或根据互联网诊疗复诊规范给予的意见，如您为危急重症患者，请及时前往医院线下就诊。
                </div>
                <div class="text">
                    &nbsp;&nbsp;&nbsp;7、如有任何问题，可联系我们。
                </div>
                <div class="m-top">
                        <van-button @click="illustratedShow = false" color="linear-gradient(to right, #02d3d9, #09e0c0)" type="primary" block>返 回</van-button>
                    </div>
               
            </div>
        </van-popup>
        <van-popup v-model="InformedShow"  position="top" closeable :overlay="true">
            <div class="Informed">
                <h4 style="text-align: center;" class="agreementtitle">互联网诊疗风险告知及知情同意书</h4>
                <div>
                    <div class="text-title">互联网诊疗服务潜在风险告知</div>
                    <div class="text">
                        &nbsp;&nbsp;&nbsp;接受互联网诊疗服务可能出现潜在风险，有些不常见的风险未能一一列出，如果换做有疑问应与医生讨论。
                    </div>
                    <div  class="text">
                        &nbsp;&nbsp;&nbsp;受限于互联网诊疗本身的局限性（如医生不能面诊、触诊等，无法通过相关的诊查手段及检查、检验结果准确判断病情的进展），医生给出的本次诊疗方案、健康管理方案、远程医疗方案依赖于用户所上传的资料和描述的症状，以及既往的病例资料、临床诊断。如前述信息不准确或不全面，将对本次诊疗方案的合理制定产生一定的影响。
                    </div>
                    <div  class="text">
                        &nbsp;&nbsp;&nbsp;提供互联网诊疗服务的医生，可能来自于实体医疗机构中的不同级别、性质的医院，执业年限及经验不一。互联网医院的分诊主要依赖于用户的需求尽力匹配适合用户的医生为患者进行服务。用户可以自行选择医生，但受限于医生的执业经验及背景，医生给诊疗方案的合理性和先进性可能存在差异。
                    </div>
                    <div  class="text">
                        &nbsp;&nbsp;&nbsp;由于精神心理疾病本身的特殊性和复杂性，患者本身的体质状况，及现有医疗水平条件的限制等，都存在可能发生各种并发症和危害自身生命健康的意外风险。由于精神心理疾病本身的复杂性，以及诊疗措施疗效出现的延后性，诊疗方案、健康管理方案、远程医疗方案可能不会达到患者期许的效果，且有些疾病或并发症是不可根治的，需要患者积极配合，长期坚持治疗，才能延缓疾病的进展。医生已经尽力为患者制定合理的方案，致力减少药物治疗不良反应的发生，但不可能完全避免，且不可预测，需要在患者的配合下，并根据临床情况不断调整方案。

                        疾病的治愈需要患者谨遵医嘱、健康管理方案，并积极配。如果患者未完全遵守和配合，则可能导致诊疗效果不理想，甚至出现病情反复、恶化等不良后果。

                        配送药品可能受到物流时效的影响，导致患者延时服用药物，或患者正在用药物或者手术等治疗其他疾病，也可能存在延时用药、联合用药等风险。

                        医生主要解决本专业领域的医疗问题，非本专业的疾病需要到其它专业科室进行诊治或接受远程医疗服务，互联网线上诊疗服务对持续维护健康具有必要性，如患者难以提供全部既往的诊疗资料，则可能导致健康档案在使用时因资料收集不全而存在潜在风险。

                        互联网医院对患者信息按要求采取了保密措施，但泄密的可能性依然不能完全消除。

                        患者的医疗文件和诊疗数据，独属于医疗机构，可能被用于科学研究（包括但不限于大数据研究），患者并不能从中直接受益。
                    </div>
                    <div class="text-title">用户知情同意确认：</div>
                    <div class="text">
                        用户确认在互联网医院上问诊的疾病，已经在实体医疗机构明确诊断，患者已经填写或上传相关的病历资料，愿意互联网诊疗。<br>

                        用户确认既往发生过与本次发病类似的常见病、慢性病病症，并曾经在实体医院诊疗，但现在无法提供与本次病症相关的检诊资料。患者愿意接受互联网医院提供的线上诊疗服务，并协助医生完善健康档案，达成互联网诊疗。<br>

                        用户确认愿意接受医生根据诊疗经验为患者安排的远程医疗服务。<br>

                        用户确认已经知晓并同意以上内容，理解相关的风险，愿意接受互联网医院的服务以及接受疾病诊疗服务，并签署知情同意书。<br>

                        用户确认未得到服务结果会百分之百成功的许诺。<br>

                        您同意您的诊疗内容在去除姓名、头像、出生日期等信息后将设置为默认展示，医生给您的指导建议同时也会帮助其他相似情况的用户。
                    </div>
                    <div class="m-top">
                        <van-button @click="InformedShow = false" color="linear-gradient(to right, #02d3d9, #09e0c0)" type="primary" block>返 回</van-button>
                    </div>
                </div>
            </div>
        </van-popup>
        <van-action-sheet v-model="show" title="已选择历史药品">
            <div v-for="(item,index) of Drugs" class="bpd margin_top" >
            <div style="display: flex;" >
                <div style="width:100px">
                <van-image width="100" height="100" :src="item.imgurl" />
                </div>
                <div style="width: 0; flex-grow: 1;">
                    <div style="font-size: 16px; padding:1px 5px;">
                  {{item.name}}
                </div>
                <div  style="font-size: 16px; padding:1px 5px;">
                  规格：{{ item.PublicDrugs.length !==0 ? item.PublicDrugs[0].specs : '' }}
                </div>
                <div  style="font-size: 12px; padding:1px 5px;">
                  生产企业：{{ item.PublicDrugs.length !==0 ? item.PublicDrugs[0].manufacturingEnterprise : '' }} 
                </div>
                <div  style="font-size: 12px; padding:1px 5px;">
                  国药准字：{{ item.PublicDrugs.length !==0 ? item.PublicDrugs[0].approvalNumber : '' }} 
                </div>
                <div  style="font-size: 12px; padding:1px 5px;">
                  医保编码：{{ item.PublicDrugs.length !==0 ? item.PublicDrugs[0].drugcode : '' }} 
                </div>
                <div class="price_number">
                    <span v-if="item.price !== '0'" class="margin-left4 price">￥{{ item.price == 'None' ? 0  : item.price * item.number}}</span>
                    <div @click.stop style="padding:0 10px;">
                    <van-stepper input-width="20px" button-size="20px" v-model="item.number"  min="0"   :max="item.maxNumber"/>
                    <van-button type="danger" @click="drugsdefault(item,index)" size="mini">删除</van-button>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </van-action-sheet>
        <van-action-sheet v-model="CurrentShow" title="当前用药">
            <div v-for="(item,index) of Current" class="bpd margin_top" >
            <div style="display: flex;" >
                <div style="width:100px">
                    <van-image width="100" height="100" :src="item.imgurl" />
                <!-- <van-image width="100" height="100" :src="item.imgurl[0] ? item.imgurl[0] :''" /> -->
                </div>
                <div style="width: 0; flex-grow: 1;">
                    <div style="font-size: 16px; padding:1px 5px;">
                  {{item.name}}
                </div>
                <div  style="font-size: 16px; padding:1px 5px;">
                  规格：{{ item.PublicDrugs.length !==0 ? item.PublicDrugs[0].specs : '' }}
                </div>
                <div  style="font-size: 12px; padding:1px 5px;">
                  生产企业：{{ item.PublicDrugs.length !==0 ? item.PublicDrugs[0].manufacturingEnterprise : '' }} 
                </div>
                <div  style="font-size: 12px; padding:1px 5px;">
                  国药准字：{{ item.PublicDrugs.length !==0 ? item.PublicDrugs[0].approvalNumber : '' }} 
                </div>
                <div  style="font-size: 12px; padding:1px 5px;">
                  医保编码：{{ item.PublicDrugs.length !==0 ? item.PublicDrugs[0].drugcode : '' }} 
                </div>
                <div class="price_number">
                    <!-- <span  class="margin-left4 price">￥0</span> -->
                    <div @click.stop style="padding:0 10px; display: flex; justify-content: end; width: 100%;">
                        <van-button type="danger" @click="currentdefault(item,index)" size="mini">删除</van-button>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </van-action-sheet>
        <el-drawer
            :visible.sync="Presdrawer"
            direction="btt"
            :show-close="false"
            :wrapperClosable="false"
        >
            <div class="textCenter" v-if="activeName == '0'">
                <p class="text-style margin-top10">正在连线快诊医生接诊<i class="el-icon-loading"></i></p>
                <p class="margin-top10">快诊医生可以更快的为您接诊和问诊</p>
                <el-progress :format="format" class="margin-top10" type="circle" :text-inside="false" :percentage="ProgressPres" ></el-progress>
                <p class="margin-top10 border" @click="activeName = '3';">不连线了，等待医院排班医生</p>
            </div>
            <div class="textCenter" v-if="activeName == '2'">
                <p class="text-style margin-top10">快诊医生</p>
                <p class="margin-top10">快诊医生可以更快的为您接诊和问诊</p>
                <p class="margin-top10 PresbtnFontSize">
                    <el-button @click="afterPres " icon="el-icon-notebook-2"  type="primary">继续等待快诊医生</el-button>
                </p>
                <p class="margin-top10" >{{reckonbytime}}秒后自动选择去医院排队</p>  
            </div>
            <div class="textCenter" v-if="activeName == '3'">
                <p class="text-style margin-top10" v-if="PresInfo.doctorId !== 'None'">
                    {{`快诊医生已接诊`}}
                </p>
                <p v-else class="text-style margin-top10" >
                    {{`由医院安排医生接诊`  }}
                </p>
                <p class="margin-top10">请支付订单后马上和医生沟通</p>
                <p class="margin-top10 Pres0btn">
                    <el-button @click="YBPayImg(PresInfo.pres_id)" v-if="PresInfo.pres_id !=='None'"  icon="el-icon-bank-card" type="primary"> 申请问诊</el-button>
                    <el-button @click="pay_action" v-else icon="el-icon-bank-card"   type="primary"> 申请问诊</el-button>
                </p>
            </div>
        </el-drawer>
        <footer_/>
      </div>
    </div>
  </template>
  <script>
  import footer_ from '../components/footer.vue'
  import {TUIChatService} from "@tencentcloud/chat-uikit-engine";
  import Quagga from 'quagga';
  import TUIKit from '../main.ts'
  import imgFun from '../assets/js/img.js'
  import barCode from './components/barCode.vue'
  import base from './api/base.js'
  import mixin from "@/utils/mixin";
  import method from '../assets/js/util.js'
  import util from '../assets/js/util.js'
  export default {
    mixins: [mixin],
    components: {
        barCode,
        footer_,
    },
    data() {
      return {
        regStr:/[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/gi,
        patientId:'',
        barCode_show:false,
        Presdrawer:false,
        ProgressPres:0,
        doctorId:'',
        conditions:'',
        re_diagnosis:false,
        pastHistory:false,
        illustratedShow:false,
        doctor:{},
        Drugs:[],
        PresFild:[],
        allergy:'',
        checked:false,
        im_id:'',
        url:'',
        price:0,
        diseaseList:[],
        symptomList:[],
        symptomSelected:[],
        presDownUrl:[],
        CheckInquiry:{
            suggest:'',
            title:''
        },
        CurrentShow:false,
        PatientList:[],
        presImgUrl:[],
        Current:[],
        family:[],
        past:[],
        show:false,
        pres_body:{},
        if_dispatch:'0', // 是否开启抢单模式 0关闭 / 1开启
        InformedShow:false,
        activeName:'0',
        reckonbytime:0,
        doctorInfo:{},
        create_pres_success:false,
        loading:null,
        PresInfo:{
           
        },
        timeSetInterval:null,
        times:null
      }
    },
    methods: {
        format(percentage){
            return percentage/4 + '秒';
        },
        imgdelete(file, detail){
            this.presDownUrl.splice(detail.index,1)
        },
        PatientChange(){
            sessionStorage.setItem('patientId', this.patientId);
            let user = this.PatientList.find(item=>{
                return item.patient_id == this.patientId
            })
            let that = this
            if(user.code == '200'){
                clearInterval(that.times);
                that.times = setInterval(function() {
                    if(that.$route.name == 'Drugs'){
                        that.$api.article.HelpDrugs(sessionStorage.getItem('pharmacy_id'),user).then(res=>{
                        console.log(res)
                            if(res.data.count !==0){
                                that.Drugs = res.data.result.Drugs
                                clearInterval(that.times);
                            }
                        })
                    }
                    
                }, 3000);
              
            }
           
        },
        afterRead(file, detail){
            console.log(file)
            
            file.forEach(element => {
                let Blob = this.base64ToBlob(element.content)
                let formData = new FormData();
                formData.append('file',Blob,'123456.png');
                this.$api.article.upload('Pres',formData).then(res=>{
                    this.presDownUrl.push(res.data.result.file_name)
                    console.log(this.presImgUrl)
                    console.log(this.presDownUrl)
                    element.status = null
                })
            });
           
        },
        base64ToBlob(dataurl) {
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime });
        },
        symptomCli(name){
            if(this.symptomSelected.indexOf(name) == -1){
                this.symptomSelected.push(name)
            }else{
                this.symptomSelected.splice(this.symptomSelected.indexOf(name),1)
            }
            
            this.conditions = this.symptomSelected.length !==0 ? `我有点${this.symptomSelected.toString()}` :''
            this.conditions = this.conditions.replace(/,/g, '，')
        },
        symptomType(name){
            if(this.symptomSelected.indexOf(name) == -1){
                return 'success'
            }else{
                return 'info'
            }
        },
        drugsdefault(item,index){
            this.Drugs.splice(index,1)
            console.log(this.Drugs)
            let drugSelected = JSON.parse(sessionStorage.getItem('drugSelected'))
            drugSelected.splice(index,1)
            sessionStorage.setItem('drugSelected', JSON.stringify(drugSelected))
        },
        currentdefault(item,index){
            this.Current.splice(index,1)
        },
        drugsShow(){
            this.show = true
        },
        triggerFileInput() {
            this.barCode_show = true
            // this.$refs.customFileInput.click();
        },
        Informed(){
            this.InformedShow = true
        },
        CurrentFun(){
            this.CurrentShow = true
        },
        Drugsfun(){
          let str = ''
          this.Current.forEach(element => {
            str+=element.name
          });
          return str
        },
        diseasefun(data){
            let str = ''
            this[data].forEach(element => {
            str+=element.title
            });
            if(str.length >=10){
                str = this[data][0].title+'...'
            }
            return str
        },
        disease(type){
            let DrugsInfo = {
                patientId:this.patientId,
                doctorId:this.doctorId,
                conditions:this.conditions,
                doctor:this.doctor,
                im_id:this.im_id,
                PatientList:this.PatientList,
            }
            sessionStorage.setItem('DrugsInfo', JSON.stringify(DrugsInfo))
            this.$router.push({
                name:'disease',
                query:{
                    type:type,
                }
            })
        },
        yaopionxuanze(type){

            let user = this.PatientList.find(item=>{
                    return item.patient_id == this.patientId
                })

            if(user.code !== 200){

                const h = this.$createElement;
                    this.$msgbox({
                        title: '消息',
                        message: h('p', { style: 'text-align: center' }, [
                            h('span', null, '当前就诊人尚未获得医保授权'),
                            h('br'),
                            h('i', { style: 'font-weight: 800' }, '请点击“授权”按钮进行授权'),
                            h('br'),
                            h('i', null, '授权完成后，'),
                            h('i', { style: 'font-weight: 400' }, '请再次进入此页面')
                        ]),
                        showCancelButton: true,
                        confirmButtonText: '授权',
                        cancelButtonText: '取消',
                        beforeClose: (action, instance, done) => {
                          if(action == 'confirm'){
                            window.location.href= 'https://portal.yuanhuimiaozhen.com/insurance-center/api/pres/auth'
                          }else{
                            done();
                          }
                        }
                        })

                return 
            }

            let DrugsInfo = {
                patientId:this.patientId,
                doctorId:this.doctorId,
                conditions:this.conditions,
                doctor:this.doctor,
                im_id:this.im_id,
                PatientList:this.PatientList,
            }
            sessionStorage.setItem('DrugsInfo', JSON.stringify(DrugsInfo))
            this.$router.push({
                name:'yaopinxuanzhe',
                query:{
                    type:type,
                }
            })
        },
        onClickLeft(){
            sessionStorage.removeItem('Drugs');
            sessionStorage.removeItem('Current');
            sessionStorage.removeItem('disease');
            this.$router.back(-1)
        },
        returnStr(data,key,DrugsState=false){
            console.log(data)
            let str = ''
            if(data.length == 0){
                str = '无'
            }
            for (var i = 0; i < data.length; i++) {
                
                if(DrugsState){
                    str+=`${data[i][key]}（${data[i].PublicDrugs[0].specs}×${data[i].number}`
                    if(data[i].PublicDrugs[0].dosage_txt !== '' && data[i].PublicDrugs[0].dosage_txt !== 'None'){
                        str+= `用法用量：（${data[i].PublicDrugs[0].dosage_txt }）`
                    }
                    if(data[i].PublicDrugs[0].indication !== '' && data[i].PublicDrugs[0].indication !== 'None'){
                        str+= `,适应症：（${data[i].PublicDrugs[0].indication}）`
                    }
                    
                }else{
                    str += data[i][key];
                    
                }
                if (i < data.length - 1) {
                    str += ',';
                }
            }
            return str
        },
        async post(){
      
            let medicine = []
            let DRGDatabase_name = []
            this.diseaseList.forEach(element => {
              DRGDatabase_name.push({name:element.title})
            });
            let Drugs = this.Drugs
            if(Drugs.every(medicine => medicine.PublicDrugs[0].class_ab === '丙类')){
                this.$message({
                    message: '您申请的药品，均不能参与报销，故不需要申请统筹报销。',
                    type: 'success'
                });
                return
            }
            Drugs.forEach(element => {
                 medicine.push(
                    {
                        skuId:element.PublicDrugs[0].id,
                        total:`${element.number}`
                    }
                )
            });
            let patient = this.PatientList.find(item=>{
                return item.patient_id == this.patientId
            })
            let texts = [
                `医生你好，我是${patient.name} `,
                `性别：${patient.sex == '1' ? '男' : '女'}`,
                `年龄：${util.mymethod(patient.birthday)}，`,
                `我的历史用药为：${this.returnStr(this.Drugs,'name',true)}，`,
                `确诊的疾病为：${this.returnStr(this.diseaseList,'title')}，`,
                `当前病情为：${this.conditions.replace('我有点','')}`,
                `过往的疾病史为：${  this.returnStr(this.past,'title')  }，`,
                `过敏情况为：${this.allergy == '' ? '无' : this.allergy}，`,
                `我是复诊`,
                `请尽快帮我问诊，谢谢`,
            ]
            console.log(texts)
            let pres_body = {
                presInfo:this.pres_body,
                Drugs:this.DrugsSieveRemoval(this.Drugs),
                Current:this.Current,
                diseaseList:this.diseaseList,
                past:this.past,
                allergy:this.allergy,
                family:this.family,
                PresFild:this.PresFild,
                patient:this.PatientList.find(item=>{return item.patient_id == this.patientId}),
                text:JSON.stringify(texts)
            }
            
           let data = {
                   created:{
                        patientId:this.patientId,
                        consultType:1,
                        price:this.price,
                        conditions:this.conditions,
                        historyContent:'无',
                        allergyContent:'无',
                        birthContent:'无',
                        presDownUrl:'',
                        isSmoking:0,
                        isDrink:0,
                        isFeritin:0,
                        isDislipidemia:0,
                        isDiabetes:0,
                        imgs:JSON.stringify(this.presDownUrl),
                        familyhis:'无',
                        pres_body:JSON.stringify(pres_body),
                        medicine:JSON.stringify(medicine),
                        pharmacy_id:this.pharmacy_id,
                        presImgUrl:'',
                        if_dispatch:this.if_dispatch == '1' ? 1 : 0,
                        DRGDatabase_name:JSON.stringify(DRGDatabase_name) 
                    }
            }
            this.post_pres(data,'afterPres')
        },
        DrugsSieveRemoval(Drugs){
            console.log(Drugs)
            let DrugsS = []
            Drugs.forEach(element => {
                DrugsS.push(
                    {
                        name:element.name,
                        imgurl:element.imgurl,
                        number:element.number,
                        pharmacy_id:element.pharmacy_id,
                        id:element.id,
                        drugcode:element.drugcode,
                        summary:element.summary,
                        PublicDrugs:[
                            {
                                name:element.PublicDrugs[0].name,
                                otc:element.PublicDrugs[0].otc,
                                commonName:element.PublicDrugs[0].commonName,
                                approvalNumber:element.PublicDrugs[0].approvalNumber,
                            }
                        ]
                    }
                )
            });
            return DrugsS
        },
        post_pres(data,funName){
            const loading = this.$loading({
                lock: true,//lock的修改符--默认是false
                text: '正在为您选择更快的互联网医院为您申请问诊',//显示在加载图标下方的加载文案
                color:'rgba(255, 255, 255, 1)',
                spinner: 'el-icon-loading',//自定义加载图标类名
                background: 'rgba(0, 0, 0, 0.7)',//遮罩层颜色
                target: document.querySelector('#table')//loadin覆盖的dom元素节点
            });
            setTimeout(() => {
                loading.close();
            }, 10000);
            setTimeout(() => {
                let params = {
                search_db:'Pres',
            }
                this.$api.article.created(params,data).then(res=>{
                    loading.close();
                    if(res.data.code && res.data.code == '200'){
                        this.PresInfo = res.data.result
                        console.log(this.PresInfo)
                        this[funName]()
                        
                    }else{
                        this.$message('开具问诊失败！');
                    }
                })
            }, 1000);
           
        },
        afterPres(){
            this.Presdrawer = true
            let that = this
            this.activeName = '0'
            this.ProgressPres = 100
            let timeSetInterval = 5
            clearInterval(that.timeSetInterval)
            this.timeSetInterval = setInterval(function(res) {
                console.log(that.PresInfo)
                console.log(that.activeName)
                timeSetInterval = timeSetInterval - 1
               
                if(that.activeName != '0'){
                    clearInterval(that.timeSetInterval)
                }
                if(that.ProgressPres ==0){
                    console.log(that.ProgressPres)
                    clearInterval(that.timeSetInterval)
                    that.afterWait()
                }else{
                    that.ProgressPres = that.ProgressPres - 4
                    // console.log('获取问诊医生id')
                    that.apireturn('Pres','id',that.PresInfo.id).then(res=>{
                        console.log(res)
                        that.PresInfo = res.data.result
                        if(res.data.result.doctorId !=='None'){
                            console.log(that.PresInfo)
                            that.activeName = '3'
                            that.pay_action(false)
                            clearInterval(that.timeSetInterval)
                        }else{
                            that.$api.article.get_doctor(res.data.result.id)
                        }
                        if(timeSetInterval == 0){
                            that.$api.article.get_doctor(res.data.result.id)
                        }
                    })  
                }
            }, 1000);
        },
        afterWait(){
            this.activeName = '2'
            let that = this;
            this.reckonbytime = 10
            this.timeSetInterval = setInterval(function(res) {
                if(that.activeName !='2'){
                    clearInterval(that.timeSetInterval)
                }
                if(that.reckonbytime ==0){
                    that.activeName = '3'
                    clearInterval(that.timeSetInterval)
                }else{
                    that.reckonbytime = that.reckonbytime - 1
                }
            }, 1000);

        },
        loadList() {
            let params = {
                search_db: 'Doctor',
            };
            this.$api.article.query({
                search_db: 'Patient',
                query_type: 'equal',
            }, {
                query: {
                    user_account: JSON.parse(localStorage.getItem('user')).user_account,
                },
            }).then(async res => {
                this.patientId = sessionStorage.getItem('patientId');
                let patientList = res.data.result;

                if (!this.patientId) {
                    this.patientId = patientList[0].patient_id;
                    sessionStorage.setItem('patientId', this.patientId);
                }
                
                // 使用Promise.all来并行发起所有异步请求
                await Promise.all(patientList.map(async element => {
                    let insuranceRes = await this.$api.article.UserMedicalInsurance(element.cerno);
                    element['code'] = insuranceRes.data.result.code;
                }));

                this.PatientList = patientList;
                this.PatientChange()
                this.getPharmacy()
            });
        },
        getPharmacy(){
            this.$api.article.query({
                search_db: 'Pharmacy',
                query_type: 'first',
            }, {
                query: {
                    id: sessionStorage.getItem('pharmacy_id')
                },
            }).then(async res => {
               console.log(res)
               localStorage.setItem('drugs_id',res.data.result.drugs_id)
            });
        },
        async imLogin(id,Sig){
            const res = await this.$tim.Utils.imLogin(id,Sig)
            
        },
        IMSIG(){
            this.$api.article.InternetHospital({search_db:'IMSIG'},{}).then(res => {
                this.im_id = res.data.result.im_id
                this.imLogin(res.data.result.im_id,res.data.result.data)
                res.data.result['Imcreated_at']  = Date.now()
                sessionStorage.setItem('IMSIG', JSON.stringify(res.data.result))
                
            })
        },
        base64ToBlob(dataurl) {
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: mime });
        },
        handleFileChange(event) {
            const file = event.target.files[0];
            if (file) {
            Quagga.decodeSingle(
                {
                src: URL.createObjectURL(file),
                numOfWorkers: 0, // 使用主线程执行识别
                inputStream: {
                    size: 800,
                },
                decoder: {
                    readers: ['ean_reader'], // 选择要识别的条形码类型
                },
                },
                result => {
                if (result && result.codeResult) {
                    this.$api.article.getdrug(result.codeResult.code,this.pharmacy_id).then(res => {
                        if(res.data.result.id){
                            this.Drugs.push(res.data.result)
                            sessionStorage.setItem('Current', JSON.stringify(this.Drugs))
                        }
                    })
                } else {
                    this.$message.error('未能识别条形码');
                }
                }
            );
            }
        },
        fatherMethod() {
            this.Drugs = sessionStorage.getItem('Drugs') ? JSON.parse(sessionStorage.getItem('Drugs')) : []
        },
        barCode_show_fun(){
            this.barCode_show = false
        },
        post_Patient(){
            this.$router.push('/post_Patient')
        },
        ifOtc(){
            return this.Drugs.every(item=>{return item.PublicDrugs[0].otc == '1'})
        },
        //合理用药
        CheckInquiry_get(){
            if(this.patientId == ''){
                this.$message({
                    message: '请选择就诊人',
                    type: 'warning'
                });
                return 
            }
            let user = this.PatientList.find(item=>{
                    return item.patient_id == this.patientId
                })
            if(user.code !== 200){
                this.$message({
                    message: '当前就诊人暂未授权',
                    type: 'warning'
                });
                return 
            }
                
            if(this.Drugs.length ==0){
                this.$message({
                    message: '请选择药品',
                    type: 'warning'
                });
                return
            }
          
            if(this.diseaseList.length ==0){
                this.$message({
                    message: '请选择疾病',
                    type: 'warning'
                });
                return
            }
            if(this.conditions == ''){
                this.$message({
                    message: '请输入病情描述',
                    type: 'warning'
                });
                return
            }
            if(this.presDownUrl.length == 0){
                // if(!this.ifOtc()){
                //     this.$message({
                //         message: '请上传复诊证明文件',
                //         type: 'warning'
                //     });
                //     return 
                // }
                    this.$message({
                        message: '请上传复诊证明文件',
                        type: 'warning'
                    });
                    return 
            }
            if(!this.re_diagnosis){
                this.$message({
                    message: '请确保您是复诊',
                    type: 'warning'
                });
                return 
            }
            if(!this.pastHistory){
                this.$message({
                    message: '请确保您是有既往史',
                    type: 'warning'
                });
                return 
            }
            if(!this.checked){
                this.$message({
                    message: '请勾选我已阅读知情同意书',
                    type: 'warning'
                });
                return 
            }
            const h = this.$createElement;
            let messageList = [ ]
            this.Drugs.forEach(element => {
                messageList.push(
                    h('i', { style: 'font-weight: 800' },`${element.name} × ${element.number}`  )
                    
                )
                messageList.push(
                    h('br'),
                )
            });
            this.$msgbox({
                title: '您当前选择的历史用药为',
                message: h('p', { style: 'text-align: center' }, messageList),
                showCancelButton: true,
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                beforeClose: (action, instance, done) => {
                    console.log(action)
                    if(action == 'confirm'){
                         const loading = this.$loading({
                            lock: true,//lock的修改符--默认是false
                            text: '正在为您的问诊进行用药检查',//显示在加载图标下方的加载文案
                            color:'rgba(255, 255, 255, 1)',
                            spinner: 'el-icon-loading',//自定义加载图标类名
                            background: 'rgba(0, 0, 0, 0.7)',//遮罩层颜色
                            target: document.querySelector('#table')//loadin覆盖的dom元素节点
                        });
                        setTimeout(() => {
                            loading.close();
                        }, 10000);
                        this.$api.article.query(
                            {search_db:'Pharmacy',query_type:'first'},
                            {query:{pharmacy_id:this.pharmacy_id}}
                        ).then(res=>{
                            let PharmacyInfo = res.data.result
                            let drugsInfos = []
                            this.Drugs.forEach(element => {
                                drugsInfos.push(
                                    {
                                        drugId:element.barCode,
                                        drugName:element.name,
                                        commonName:element.name,
                                        spec:element.specs,
                                        approvalNO:element.approvalNumber,
                                        count:element.number,
                                        countUnit:element.pkUnit
                                    }
                                )
                            });
                            let diseaseInfos = []
                            this.diseaseList.forEach(element => {
                                diseaseInfos.push(
                                    {
                                        diseaseId:element.id,
                                        diseaseName:element.title
                                    }
                                )
                            });
                            let data = {
                                patientId:localStorage.getItem('userrole_id'),
                                outOrderId:localStorage.getItem('userrole_id'),
                                name:user.name,
                                birthdate:user.birthday,
                                sex:this.getGenderFromIdCard(user.sex),
                                'pMHType': 0,
                                'aMHType': 0,
                                'fMHType': 0,
                                'liverType': 0,
                                'renalType': 0,
                                'nurseType': 0,
                                companyName:PharmacyInfo.name,
                                companyId:this.pharmacy_id,
                                subDrugsTy:'0',
                                drugsInfos:JSON.stringify(drugsInfos),
                                diseaseInfos:JSON.stringify(diseaseInfos),
                                img_names:[this.presDownUrl],
                            }
                            this.$api.article.CheckInquiry(data).then(res=>{
                                loading.close();
                                if(res.data.result.data.length == 0){
                                    this.CheckInquiry = {
                                        title:'你的疾病可以申请此药品的问诊',
                                        suggest:'通过'
                                    }
                                    this.post()
                                }else{
                                    this.CheckInquiry = res.data.result.data[0]
                                    this.$message(`您的问诊未通过合理用药筛选原因如下${this.CheckInquiry.title}`);
                                }
                            })
                        })
                    }
                    done();
                    
                }
            })
           
        },
        patientChange(){
            // let params = {
            //   search_db:'Pres',
            //   query_type:'first',
            //   order_by:'desc'
            // }
            let data = {
                query:[
                    {
                        fild:{patientId:this.patientId},
                        type:'=',
                        condition:'&&'
                    }
                ],
            }
            const h = this.$createElement;
            this.$msgbox({
                title: '消息',
                message: h('p', { style: 'text-align: center' }, [
                    h('span', null, '请跳转到医保授权页面进行授权'),
                    h('br'),
                    h('i', null, '授权完成后，'),
                    h('i', { style: 'font-weight: 800' }, '请再次进入此页面')
                ]),
                showCancelButton: true,
                confirmButtonText: '去授权',
                cancelButtonText: '取消',
                beforeClose: (action, instance, done) => {
                    if(action == 'confirm'){
                    window.location.href= 'https://portal.yuanhuimiaozhen.com/insurance-center/api/pres/auth'
                    }else{
                    done();
                    }
                }
            })
            sessionStorage.setItem('patientId', this.patientId)
        },
        getGenderFromIdCard(idCard){
            var genderDigit = idCard
            // 判断性别
            if (genderDigit % 2 === 0) {
                return "2";
            } else {
                return "1";
            }
        },
        //医生接单
        pay_action(JumpState = true){
            this.loading = this.$loading({
                lock: true,//lock的修改符--默认是false
                text: '正在为您申请问诊' + JumpState ?  '马上跳转支付页面' : '',    //显示在加载图标下方的加载文案
                color:'rgba(255, 255, 255, 1)',
                spinner: 'el-icon-loading',//自定义加载图标类名
                background: 'rgba(0, 0, 0, 0.7)',//遮罩层颜色
                target: document.querySelector('#table')//loadin覆盖的dom元素节点
            });
            if(this.PresInfo.doctorId !== 'None'){  //医生接单
                console.log(this.PresInfo)
                this.created_order(this.PresInfo.doctorId,this.PresInfo.id,JumpState)
            }else{
                this.apireturn('Pres','id',this.PresInfo.id).then(res=>{
                    console.log(res)
                    this.PresInfo = res.data.result
                    if(res.data.result.doctorId =='None'){
                        this.$api.article.get_doctor(this.PresInfo.id).then(res=>{
                            if(res.data.result.length !==0){
                                this.doctorInfo = res.data.result[0]
                                this.created_order(this.doctorInfo.id,this.PresInfo.id)
                                
                            }else{
                                this.loading.close();
                                this.$confirm('当前暂无可用医生, 是否继续?', '提示', {
                                    confirmButtonText: '继续等待',
                                    cancelButtonText: '取消等待',
                                    type: 'warning'
                                    }).then(() => {
                                        this.afterPres()
                                    }).catch(() => {
                                             
                                    });
                            }
                        })
                    }else{
                        this.created_order(this.PresInfo.doctorId,this.PresInfo.id)
                    }
                })  
                
            }
            
        },
        //发起互联网医院问诊
        created_order(doctorId,presID,JumpState = true){
            let that = this
            setTimeout(() => {
                that.loading.close();
                if(!that.create_pres_success){
                    this.$message.error('申请问诊失败，请重新申请');
                    that.create_pres_success = false
                }
            } , 10000);
            this.apireturn('Pres','id',presID).then(res=>{
                if(res.data.result.pres_id == 'None'){
                    let data = {
                        doctor_id:doctorId,
                        pres_id:presID,
                    }
                    this.$api.article.CreatedInternetPres(data).then(res=>{
                        this.create_pres_success = true
                        this.PresInfo = res.data.result
                        if(JumpState){
                            this.YBPayImg(res.data.result.pres_id)
                        }else{
                            this.loading.close();
                        }
                    })
                }else{
                    if(JumpState){
                        this.YBPayImg(res.data.result.pres_id)
                    }else{
                        this.loading.close();
                    }
                }
            })
            
        },
        // 跳转支付连接
        YBPayImg(pres_id){
            let that = this
            setTimeout(() => {
                that.loading.close();
            } , 10000);
            this.$api.article.YBPayImg(pres_id,1,localStorage.getItem('ih_type')).then(res=>{
                this.$alert('请支付成功后重新进入问诊', '提示', {
                   confirmButtonText: '确定',
                   callback: action => {
                       sessionStorage.removeItem('Drugs');
                       sessionStorage.removeItem('Current');
                       sessionStorage.removeItem('disease');
                       sessionStorage.removeItem('patientId');
                       if(res.data.result.data.slice(0,4) == 'http'){
                            this.loading.close();
                           window.location.href= res.data.result.data
                       }else{
                            this.loading.close();
                           that.$router.push('/message')
                       }
                   }
                  });
                
           })
        }
    },
    created() {
        this.pharmacy_id = sessionStorage.getItem('pharmacy_id');  
        if(true){
            this.if_dispatch = 1
          
        }else{
            this.$api.article.GetDoctortime().then(res=>{
                if(res.data.result.length !==0){
                    this.pres_body = {
                        presInfo: res.data.result[0],
                    };
                    this.doctorId = res.data.result[0].id;
                    console.log(this.doctorId)
                }else{
                    this.$alert('当前暂无可用医生，请稍后', '提示', {
                        confirmButtonText: '确定',
                        type: 'warning',
                        callback: action => {
                        }
                    });
                }
            })
        }
        this.Drugs = sessionStorage.getItem('Drugs') ? JSON.parse(sessionStorage.getItem('Drugs')) : []
        let Current = sessionStorage.getItem('Current') ? JSON.parse(sessionStorage.getItem('Current')) : []
        Current.forEach(item => {
            if(item.PublicDrugs.length !==0){
                item['imgurl'] = `${base.weburl}static/img/${item.PublicDrugs[0].img_url}`
            }
        });
        this.Current = Current
        this.diseaseList = sessionStorage.getItem('disease') ? JSON.parse(sessionStorage.getItem('disease')) : []
        this.past = sessionStorage.getItem('past') ? JSON.parse(sessionStorage.getItem('past')) : []
        this.family = sessionStorage.getItem('family') ? JSON.parse(sessionStorage.getItem('family')) : []
        this.url = base.baseUrl
        let user_account = JSON.parse(localStorage.getItem('userinfo'))
        this.token = user_account.token
        this.userrole_id = localStorage.getItem('userrole_id')
        let arr = [];
        let promises = [];
        this.price = 0
        this.Drugs.forEach(item => {
            if(item.price !=='None'){
                this.price +=item.price*1*item.number
            }
            if(item.PublicDrugs.length !==0){
                item['imgurl'] = `${base.weburl}static/img/${item.PublicDrugs[0].img_url}`
            }
            let params = {
                search_db: 'DiseaseSymptomDrug',
                query_type: 'like',
            };

            let data = {
                query: {
                    drug: item.name
                }
            };

            let promise = this.$api.article.query(params, data).then(res => {
                res.data.result.forEach(element => {
                    arr.push(element);
                });
            });

            promises.push(promise);
        });
        console.log(this.DrugsSieveRemoval(this.Drugs))

        // 使用Promise.all等待所有异步请求完成
        Promise.all(promises).then(() => {
            let symptomList = []
            arr.forEach(element => {
                element.symptom.split(',').forEach(item => {
                    if(symptomList.indexOf(item) == -1){
                        symptomList.push(item)
                    }
                });
            });
            this.symptomList = symptomList
        });
        this.loadList()
    },
    mounted(){
    },
    destroyed() {
        clearInterval(this.times);
    },
  }
  </script>
  <style scopde lang="less">
  .presDownUrl-cell .van-cell__value{
    overflow: auto;
    // text-overflow:clip
    white-space:normal
  }
  .presDownUrltext{
    width: 100%;
    text-align: left;
    color: #E6A23C !important;
    overflow-wrap: break-word
  }
  .pastHistorytext{
    color: #000 !important;
    font-size: 14px;
  }
  .border{
    border-bottom: 1px solid #fff;
    width: 50%;
    margin: 0 auto;
  }
  .Pres0btn{
    margin-top: 50px !important;
    width: 95%;
    margin: 0 auto;
    button{
        width: 100%;
        font-size: 25px;
    }
  }
  .PresbtnFontSize{
    width: 95%;
    margin: 0 auto;
    button{
        width: 100%;
        font-size: 20px;
    }
  }
    .text-style{
        font-size: 30px;
        font-weight: 600;
    }
    .textCenter{
        text-align: center;
        color: #fff;
    }
    .Drugs .el-drawer{
        background: rgb(140, 198, 206) !important;
        height: 50% !important;

    }
    .Drugs .el-progress-circle{
        height: 100px;
        width: 100px;
    }
    .Drugs .el-drawer__header{
        margin: 0 !important;
        padding: 0;
    }
    .Drugs .Drugs_btn_mar{
        margin-bottom: 70px;
    }
    .agreementtitle{
        font-size: 30px !important;
        font-weight: 600;
    }
    .Drugs .el-checkbox{
        margin-top: 10px;
    }
    .required{
        font-size: 13px;
        color: #F56C6C !important;
        font-weight: 600;
    }
    .required_success{
        font-size: 13px;
        color: #67C23A !important;
        font-weight: 600;
    }
    .color_Danger{
        color: #F56C6C !important;
    }
    .refuse{
        color: rgb(220, 156, 39);
    }
    .Drugs .agree{
        color: #67C23A;
    }
    .CheckInquiry{
        padding: 11px 16px;
        font-family: 600;
    }
    .symptom_auxiliary{
        padding: 12px 14px;
    }
    .Current-text{
    padding-bottom: 5px;
    color: #c0c0c0 !important;
    }
    .Current{
        padding-bottom: 0 !important;
    }
    .Drugs .line-height32{
        line-height: 32px !important;
    }
    .Drugs .margin-top12{
        margin-top: 0;
    }
    .Drugs .margin-top10{
        margin-top: 10px;
    }
    .yaowu .van-cell{
        padding: 6px 14px 0px 14px;
    }
    .padding-7{
        padding: 0 7px;
    }
    .DrugsTitle{
        font-size: 16px !important;
        font-weight: 600 !important;
    }
    .Drugs_title{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .Current .van-button--default{
        border: none;
    }
    .jiuzhenren .van-button--default{
        border: none;
    }
    .pading10{
        padding: 0 15px;
    }
    .text-title{
        font-weight: 700;
        font-family: SimHei,Source Han Sans Traditional Chinese normal,San Francisco Display Regular,STHeiti;
    }
    .text{
        font-size: 14px;
    }
    .van-popup{
        width: 100%;
        color: #323232;
        
    }
    .Informed{
        width: 100%;
        padding: 10px;
        height: 100vh;
    }
    .yyxz{
        position:relative;
        padding-bottom: 40px !important;
    }
    .yyxz_btn{
        position:absolute;
        bottom:10px;
        right:10px
    }
  
    #Interrogation .consent{
        color: #000 !important;
    }
    #Interrogation .consent_color{
        color: #20d8de !important;
    }
    .jiuzhenren{
        // height: 5.4rem;
        .jiuzhenren_xuanzhe{
            // margin: 10px 0;
            .el-select{
                width: 45%;
            }
        }
    }
    .yyxz{
        height: 6rem;
        .yyxz_nr{
            color: #999;
            font-size: 14px;
            text-align: right;
        }
    }
    .van-field__textarea-label{
        font-size: 16px;
        color: #111 !important;
        font-weight: 500 !important;
    }
    .title_icon{
        color: #02d4d8;
        font-weight: 900;
        font-size: 17px;
    }
    .van-cell__value{
        color: #999 !important;
        font-size: 14px;
    }
    .text{
        color: rgb(192,192,192) !important;
        font-size: 14px;
    }
  </style>
  <style>
 .el-message-box{
        width: 90% !important;
    }
    .Medication_btn .van-button--default{
        border:none
    }
    .yaowu .van-button--default{
        border:none
    }
    .yyxz .van-button--default{
        border:none
    }
    .el-loading-spinner i{
    font-size: 30px;
    }
    .el-loading-spinner .el-loading-text{
        width: 72%;
        margin: 0 auto !important;
        color: #fff !important;
        font-size: 20px !important;
    }
    .Drugs .van-button--mini{
        font-size: 14px;
    }
    
</style>